import { SVGProps, Ref, forwardRef } from 'react'

const SvgMail = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.5 4a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h21a.75.75 0 0 0 .75-.75v-15A.75.75 0 0 0 22.5 4h-21Zm.75 2.562V19h19.5V6.563l-7.275 5.595a4.058 4.058 0 0 1-4.948 0L2.25 6.562ZM20.672 5.5H3.33l7.111 5.47a2.558 2.558 0 0 0 3.12 0l7.11-5.47Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgMail)
export default ForwardRef
